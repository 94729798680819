import { Text, Pressable } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Header from '../components/Header';
import BackButton from '../components/BackButton';
import IntakeContainer from '../components/IntakeContainer';

export default function AboutScreen({ dispatch }) {
    const description = "Please note that Aurora is not designed to assist with serious mental health crises and it is intended for users who are at least 18 years of age. Aurora is not a substitute for professional medical or clinical advice and cannot diagnose or treat medical conditions. If you have any concerns about your mental or physical health, please seek the advice of a qualified healthcare professional."
  return (
    <Container>
      <Header />
      <BackButton dispatch={dispatch} prevScreen='About' />
      <IntakeContainer>
        <Text
          style={{
            textAlign: 'center',
            paddingHorizontal: 50,
            paddingVertical: 30,
            fontSize: 18,
            maxWidth: 400,
            color: '#696969'
          }}
        >{description}</Text>
        <Pressable
        style={{
          width: 150,
          borderColor: '#EBEBEB',
          borderWidth: 1,
          borderRadius: 20,
          margin: 10,
          shadowColor: '#EAEAEA',
          shadowOffset: {width: 0, height: 8},
          shadowOpacity: .5,
          shadowRadius: 14,
        }}
        onPress={() => {
          dispatch({
            type: ACTIONS.CHANGE_SCREEN,
            payload: {screen: 'Chat'}
          })
        }}
      >
        <Text style={{
          // fontSize: 16,
          color: '#696969',
          textAlign: 'center',
          padding: 10,
        }}>Go to Chat</Text>
      </Pressable>
      </IntakeContainer>
    </Container>
  )
}