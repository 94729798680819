import { Text, Pressable } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Header from '../components/Header'
import BackButton from '../components/BackButton';
import IntakeContainer from '../components/IntakeContainer';

export default function TherapyBeforeScreen({ dispatch }) {
  return (
    <Container>
      <Header />
      <BackButton dispatch={dispatch} prevScreen='Name' />
      <IntakeContainer>
        <Text
          style={{
            textAlign: 'center',
            padding: 30
          }}
        >What language are you most comfortable speaking?</Text>
        <Pressable
          style={{
            width: 250,
            borderColor: '#EBEBEB',
            borderWidth: 1,
            borderRadius: 20,
            shadowColor: '#EAEAEA',
            shadowOffset: {width: 0, height: 8},
            shadowOpacity: .5,
            shadowRadius: 14,
            margin: 20
          }}
          onPress={() => {
            dispatch({
                type: ACTIONS.SET_USER_LANGUAGE,
                payload: {language: 'English'}
            })
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'Concerns'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>English</Text>
        </Pressable>
        <Pressable
          style={{
            width: 250,
            borderColor: '#EBEBEB',
            borderWidth: 1,
            borderRadius: 20,
            shadowColor: '#EAEAEA',
            shadowOffset: {width: 0, height: 8},
            shadowOpacity: .5,
            shadowRadius: 14,
            marginBottom: 20
          }}
          onPress={() => {
            dispatch({
              type: ACTIONS.SET_USER_LANGUAGE,
              payload: {language: 'Spanish'}
            })
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'Concerns'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Spanish</Text>
        </Pressable>
        <Pressable
          style={{
            width: 250,
            borderColor: '#EBEBEB',
            borderWidth: 1,
            borderRadius: 20,
            shadowColor: '#EAEAEA',
            shadowOffset: {width: 0, height: 8},
            shadowOpacity: .5,
            shadowRadius: 14,
            marginBottom: 30
          }}
          onPress={() => {
            dispatch({
                type: ACTIONS.SET_USER_LANGUAGE,
                payload: {language: 'French'}
            })
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'Concerns'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>French</Text>
        </Pressable>
        <Pressable
          style={{
            margin: 20,
            position: 'absolute',
            bottom: '10%',
            textDecorationLine: 'underline'
          }}
          onPress={() => {
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'About2'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Skip</Text>
        </Pressable>
        </IntakeContainer>
    </Container>
  )
}