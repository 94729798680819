import {
  View
} from 'react-native-web';

export default function Container({ children }) {
  return (
    <View style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white'
    }}>
      {children}
    </View>
  )
}