import {
  Text,
  Pressable
} from 'react-native-web';

import * as ACTIONS from '../constants/actions';

export default function BackButton({ dispatch, prevScreen}) {
  return (
    <Pressable
      style={{
        position: 'absolute',
        top: 15,
        left: 25,
      }}
      onPress={() => {
        dispatch({
          type: ACTIONS.CHANGE_SCREEN,
          payload: {screen: prevScreen }
        })
      }}
    >
      <Text style={{
        fontSize: 28,
        color: '#BFBFBF',
      }}>&#5176;</Text>
    </Pressable>
  )
}