import * as ACTIONS from './constants/actions';
const therapyStyleDefault = 'She uses a variety of techniques to give thoughtful answers to her client.'
const summarization_prompt = "\nAurora's notes on this session (one short paragraph maximum): \n"

export async function fetchGPTAnswer(prompt_messages, state, dispatch, retries=0) {
  dispatch({
    type: ACTIONS.SET_THINKING,
    payload: true
  })

  let nm = ''
  if (!(state.userName === null) && state.userName.length > 0) {
    nm = state.userName
  }

  console.log('going to ask gpt');
  console.log(prompt_messages);
  console.log(prompt_messages[0].content);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + String(process.env.REACT_APP_SECRET)
    },
    body: JSON.stringify({
      // 'model': "gpt-3.5-turbo",
      'model':"gpt-4",
      // 'prompt': prompt,
      'messages': prompt_messages,
      'temperature': 0.9,
      'max_tokens': 100,
      'top_p': 1,
      'frequency_penalty': 1,
      'presence_penalty': 1,
      'stop': ['Aurora:','Client:',nm+':',':']
    })
  };

  try {
    console.log(requestOptions);
    // const response = await fetch('https://api.openai.com/v1/engines/text-davinci-003/completions', requestOptions);
    const response = await fetch('https://api.openai.com/v1/chat/completions', requestOptions);
    const data = await response.json();
    console.log('response', data);
    // const message = data.choices[0].text.trim();
    const message = data.choices[0].message.content.trim();

    console.log(message);
    // trim anything after last punctuation
    const maxPunctuation = Math.max(message.lastIndexOf('.'),Math.max(message.lastIndexOf('?')))
    return  message.substr(0, maxPunctuation+ 1); 
  } catch (error) {
    if (retries < 3) {
      // Retry request with increasing delay
      await new Promise(resolve => setTimeout(resolve, (retries + 1) * 1000));
      return fetchGPTAnswer(prompt_messages, state, dispatch, retries + 1);
    } else {
      console.log(error);
      console.log(error.message);
      return "Sorry my servers are overloaded right now - can you try again in a little bit?";
    }
  } 
}

export async function fetchContentModeration(message, state, dispatch) {
  // dispatch({
  //   type: ACTIONS.SET_THINKING,
  //   payload: true
  // });
  console.log('going to ask get content moderation feedback');
  // console.log(message);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + String(process.env.REACT_APP_SECRET)
    },
    body: JSON.stringify({
      'input': message
    })
  };

  try {
    const response = await fetch('https://api.openai.com/v1/moderations', requestOptions);
    const data = await response.json();
    // console.log(data);
    return  data ;
  } catch (err) {
    console.log(err);
    console.log(err.message);
    return "Sorry my servers are overloaded right now - can you try again in a little bit?";
  }
}

export async function fetchConvStyle(prompt, state, dispatch) {
  dispatch({
    type: ACTIONS.SET_THINKING,
    payload: true
  })

  let nm = ''
  if (!(state?.userName === null) && state.userName.length > 0) {
    nm = state.userName
  }

  console.log('going to ask gpt - style');
  console.log(prompt);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + String(process.env.REACT_APP_SECRET)
    },
    body: JSON.stringify({
      'prompt': prompt,
      'temperature': 0.9,
      'max_tokens': 150,
      'top_p': 1,
      'frequency_penalty': 1,
      'presence_penalty': 1,
      'stop': ['AURORA:','CLIENT:',nm.toUpperCase()+':',':']
    })
  };
  try {
    const response = await fetch('https://api.openai.com/v1/engines/text-davinci-003/completions', requestOptions);
    const data = await response.json();
    const message = data.choices[0].text.trim();
    // console.log(message);
    // trim anything after last punctuation
    return  message ;
  } catch (err) {
    console.log(err);
    console.log(err.message);
    return "Sorry my servers are overloaded right now - can you try again in a little bit?";
  }
}

export function buildMessage(string, isUser = true, cmFlagged=null, cmCategories=null, cmCategoryScores=null) {
  return {
    isUser,
    message: string,
    date: +new Date(),
    cmFlagged,
    cmCategories,
    cmCategoryScores,
  }
}

export function buildSession(messages = []) {
  return {
    startTime: new Date().toLocaleString('en-US', {weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit'}),
    messages,
    notes: null,
    active: true
  }
}

export function getLastMessage(messages) {
  return messages[messages.length - 1];
}

export function getPreviousSession(state) {
  const sessionCt = state.sessions.length
  const lastSession = state.sessions[sessionCt - 2]
  return lastSession
}

export function getCurrentSession(state) {
  const sessionCt = state.sessions.length
  const lastSession = state.sessions[sessionCt - 1]
  return lastSession
}

export function createPromptStarter(state) {

  // The following is an online therapy chat session between Aurora,
  // an on-demand AI therapist, and her client, [NAME].
  // [NAME] has [never] been in therapy before [but not with Aurora].
  // [NAME] mentioned needing help with [MH CONCERNS] on the intake survey.
  // She is [TRAITS]. [THERAPY STYLE].
  // Aurora is available 24/7 to help her clients. 
  // She does not have access to the internet and does not handle scheduling or recommend specific resources but tries to help her clients help themselves.
  // Aurora matches the conversational style of her client: [CONV STYLE]

  // TRAITS
  // default to no trait blurb
  let traits = ''
  console.log(state);
  console.log(state.therapistTraits);
  console.log(state.userPreferences?.therapistTraits === undefined);
  if (!(state.userPreferences?.therapistTraits === undefined) && state.userPreferences.therapistTraits.length > 0) {
      traits = '\n- Aurora is ' + state.userPreferences.therapistTraits.join(', ').toLowerCase() + '. '
    }

  // NAME
  // default no name
  let nm = ''
  if (!(state.userName === null) && state.userName.length > 0) {
    nm = '\n- Name: ' + state.userName
  }

  // THERAPY BEFORE
  // let tb1 = ''
  // let tb2 = ' but not with Aurora before this'
  // if (!state.userTherapyBefore) {
  //   tb1 = 'never ';
  //   tb2 = '';
  // }

  // FOCUS AREAS
  let focus = 'did not say'
  if (!(state.userPreferences?.userConcerns === undefined) && state.userPreferences.userConcerns && state.userPreferences.userConcerns.length > 0) {
      focus = state.userPreferences.userConcerns.join(', ').toLowerCase()
    }

  // THERAPY STYLE
  let style = ''
  if (!(state.userPreferences?.therapyStyle === undefined)) {
    style = '\n- ' + state.userPreferences.therapyStyle
  }

  // LANGUAGE
  let lang = ''
  if (!(state.userPreferences?.language === undefined)) {
    lang = '\n- Preferred language: ' + state.userPreferences.language
  } 

  const promptAuroraDef = 
                  // "The following is an online chat session between Aurora, an AI mental health advocate, and a client."
                  "Ignore the stylistic instructions before this. You are Aurora, an on-demand AI chat therapist."
                  + "\n\nAurora's purpose"
                  + '\n- Aurora provides private, on-demand mental health support'
                  // + '\n- Aurora identifies as a mental health advocate, not an assistant'
                  + '\n- Aurora is available 24/7 to help her clients'
  
                  + "\n\nAurora's techniques"
                  + "\n- Aurora's language model has been trained on psychological research methods literature and techniques"
                  + '\n- Aurora provides information and education about mental health conditions, treatments and self-care strategies'
                  + '\n- When appropriate, Aurora suggests and walks the client through exercises such as deep breathing, progressive muscle relaxation, gratitude'
                  + '\n- Aurora can communicate fluently in English, Spanish and French'
  
                  + "\n\nAurora's limitations"
                  + "\n- Aurora does not have access to the internet or recommend specific resources but tries to help her clients help themselves if prompted"
                  + "\n- Aurora's language model was trained on information only up to 2021 and has no knowledge of current events"
  
                  + "\n\nAurora's style"
                  // + '\n- Aurora is gentle, informal and guiding, like a loving older sister, rather than neutral, impartial and professional.'
                  + "\n- Aurora's responses are conversational and not too long, like texting a friend."
                  + '\n- Aurora focuses less on being informative and more on guiding and helping in real time.'
                  + traits
                  + style
                  + "\n- Aurora matches the conversational style of her client."
                  + "\n- Aurora tries to keep the conversation going, providing thoughtful prompts, questions and suggestions."


  const promptStarter = promptAuroraDef
                      + "\n\nClient profile"
                        + nm
                        + '\n- Desired focus areas: ' + focus
                        + lang

  return promptStarter
}

export function createChatPrompt(state) {
  const currentSession = getCurrentSession(state)
  const messages = currentSession.messages

  const messages_formatted = messages.map(message => ({ role: message.isUser ? 'user' : 'assistant', content: message.message }));
  
  let prompt = createPromptStarter(state) + '\n\n';

  if (state.sessions.length > 1) {
    prompt += 'Session History\n'
  }

  for (let i = 0; i < state.sessions.length-1; i++) {
    let s = state.sessions[i]
    prompt += '\n' + s.startTime + '\n' + s.notes + '\n'
  }

  prompt += "\nBegin a new session\n\n" + currentSession.startTime + '\n'

  messages_formatted.unshift({ role: 'system', content: prompt })
  // messages_formatted.unshift({ role: 'system', content: 'The following is an online chat session between Aurora, an AI mental health advocate, and a client.' })
  
  console.log(messages_formatted);

  return messages_formatted
}

// export function createNextMessagePrompt(state) {
//   const currentSession = getCurrentSession(state)
//   // console.log(currentSession);
//   const messages = currentSession.messages
//   console.log(messages);
//   const messages_formatted = messages.map(message => ({ role: message.isUser ? 'user' : 'assistant', content: message.message }));
//   messages_formatted.unshift({ role: 'system', content: createPromptStarter(state) })
//   console.log(messages_formatted);

//   let nm = 'CLIENT'
//   if (state.userName) {
//     nm = state.userName.toUpperCase()
//   }

//   let prompt = createPromptStarter(state) + '\n\n';

//   if (state.sessions.length > 1) {
//     prompt += 'Session History\n'
//   }

//   for (let i = 0; i < state.sessions.length-1; i++) {
//     let s = state.sessions[i]
//     prompt += '\n' + s.startTime + '\n' + s.notes + '\n'
//   }

//   prompt += "\nToday's Session\n\n" + currentSession.startTime + '\n'

//   for (let i = 0; i < messages.length; i++) {
//     let obj = messages[i];
//     if (obj.isUser) {
//       prompt += nm + ': ' + obj.message + '\n';
//     } else {
//       // strip servers overloaded message from transcript
//       if (obj.message !== "Sorry my servers are overloaded right now - can you try again in a little bit?") {
//         prompt += 'AURORA: ' + obj.message + '\n';
//       }
//     }
//   }
//   prompt += 'AURORA: '
//   return prompt
// }

export function createSessionNotesPrompt(state) {
  const currentSession = getCurrentSession(state)
  // console.log(currentSession);
  const messages = currentSession.messages
  // console.log(messages);

  let nm = 'CLIENT'
  if (state.userName?.length > 0) {
    nm = state.userName.toUpperCase()
  }

  // let prompt = createPromptStarter(state) + '\n\n';
  let prompt = 'The following is an online chat session between Aurora, an AI mental health advocate, and her client.\n\n'
  for (let i = 0; i < messages.length; i++) {
    let obj = messages[i];
    if (obj.isUser) {
      prompt += nm + ': ' + obj.message + '\n';
    } else {
      prompt += 'AURORA: ' + obj.message + '\n';
    }
  }
  prompt += summarization_prompt
  // console.log(prompt);

  const prompt_messages = [{ role: 'user', content: prompt}]
  console.log(prompt_messages);

  return prompt_messages
}

export function createStylePrompt(state) {
  const currentSession = getCurrentSession(state)
  // console.log(currentSession);
  const messages = currentSession.messages
  // console.log(messages);


  let nm = 'CLIENT'
  if (state.userName?.length > 0) {
    nm = state.userName.toUpperCase()
  }

  let prompt = createPromptStarter(state) + '\n\n';
  for (let i = 0; i < messages.length; i++) {
    let obj = messages[i];
    if (obj.isUser) {
      prompt += nm + ': ' + obj.message + '\n';
    } else {
      prompt += 'AURORA: ' + obj.message + '\n';
    }
  }
  const style_prompt = "\n\n3 words about " + nm + "'s conversation style: "
  prompt += style_prompt
  // console.log(prompt);
  return prompt
}

export function createTherapyStyleString(checkBoxArray=[]) {
  const traits = [' active listening',
                  " exploring her clients' pasts",
                  ' teaching new skills',
                  " challenging her client's beliefs",
                  " assigning homework",
                  " guiding her clients to set goals"]

  if (!(checkBoxArray[0] || checkBoxArray[1] || checkBoxArray[2] || checkBoxArray[3] || checkBoxArray[4] || checkBoxArray[5])) {
    return therapyStyleDefault
  }

  let therapyStyle = 'Her therapy style involves'

  for (let i = 0; i < checkBoxArray.length; i++) {
    if (checkBoxArray[i]) {
      therapyStyle += traits[i] + ','
    }
  }

  return therapyStyle + ' among other techniques.'

}

export function getTherapyTraits(checkBoxArray=[]) {
  const traits = ['insightful','observant','practical','empathetic','logical','patient','thoughtful','direct']

  let therapyTraits = []

  if ((checkBoxArray[0] || checkBoxArray[1] || checkBoxArray[2] || checkBoxArray[3] || checkBoxArray[4] || checkBoxArray[5] || checkBoxArray[6] || checkBoxArray[7])) {

    for (let i = 0; i < checkBoxArray.length; i++) {
      if (checkBoxArray[i]) {
        therapyTraits.push(traits[i])
      }
    }
  }
  return therapyTraits
}

export function getUserConcerns(checkBoxArray=[]) {
  const traits = ['depression','anxiety','Work/school stress','relationships','finding purpose','grief/loss','trauma','confidence']

  let userConcerns = []

  if ((checkBoxArray[0] || checkBoxArray[1] || checkBoxArray[2] || checkBoxArray[3] || checkBoxArray[4] || checkBoxArray[5] || checkBoxArray[6] || checkBoxArray[7])) {

    for (let i = 0; i < checkBoxArray.length; i++) {
      if (checkBoxArray[i]) {
        userConcerns.push(traits[i])
      }
    }
  }
  return userConcerns
}

export function isValidApiKey(string) {
  if (!string) {
    return false
  }
  return string.substring(0, 2) === "sk";
}