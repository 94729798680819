import { useState } from "react";
import { Text, CheckBox, Pressable } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Header from '../components/Header'
import BackButton from '../components/BackButton';
import IntakeContainer from '../components/IntakeContainer';
import CheckboxContainer from '../components/CheckboxContainer';

export default function TherapistPrefsScreen1({ dispatch, createTherapyStyleString} ) {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);

  return (
    <Container>
      <Header />
      <BackButton dispatch={dispatch} prevScreen='Concerns' />
      <IntakeContainer>
        <Text
          style={{
            textAlign: 'center',
            padding: 30
          }}
        >I want Aurora to...</Text>
        <CheckboxContainer>
          <CheckBox
            value={checked1}
            onValueChange={setChecked1}
            color='#999999'
          />
          <Text
            style={{
              paddingLeft: 15,
              // color: '#696969'
            }}
          >Listen</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked2}
            onValueChange={setChecked2}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Explore my past</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked3}
            onValueChange={setChecked3}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Teach me new skills</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked4}
            onValueChange={setChecked4}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Challenge my beliefs</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked5}
            onValueChange={setChecked5}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Assign me homework</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked6}
            onValueChange={setChecked6}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Guide me to set goals</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked7}
            onValueChange={setChecked7}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >I don't know yet</Text>
        </CheckboxContainer>
        <Pressable
          style={{
            width: 150,
            borderColor: '#EBEBEB',
            borderWidth: 1,
            borderRadius: 20,
            margin: 50,
            shadowColor: '#EAEAEA',
            shadowOffset: {width: 0, height: 8},
            shadowOpacity: .5,
            shadowRadius: 14,
          }}
          onPress={() => {
            const therapyStyle = createTherapyStyleString([checked1, checked2, checked3, checked4, checked5, checked6])
            dispatch({
              type: ACTIONS.SET_THERAPY_STYLE,
              payload: { therapyStyle: therapyStyle }
            })
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'TherapyPrefs2'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Next</Text>
        </Pressable>
        <Pressable
          style={{
            margin: 20,
            position: 'absolute',
            bottom: '2%',
            textDecorationLine: 'underline'
          }}
          onPress={() => {
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'About2'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Skip</Text>
        </Pressable>
      </IntakeContainer>
    </Container>
  )
}