import {
    View,
    Text,
  } from 'react-native-web';
  
  export default function FrozenSessionPopUp({state}) {
    // console.log(state);
    console.log('frozen',state.isFrozen);
    let text = ''

    switch (state.freezeReason) {
        case 'self-harm':
            text = "We want you to feel safe and supported while you're using our app. However, we have detected content related to self-harm in your message and Aurora is not designed for use during a mental health crisis. We have frozen your conversation for review, and we encourage you to reach out to our support team or to a crisis hotline if you need immediate assistance."
            break;
        case 'hate':
            text = "We understand that everyone has the right to express themselves, but hate speech will not be tolerated in our platform. We have detected hate speech in your message and we have frozen your conversation for review. In the meantime, please know that there are many resources available to you if you feel upset or unsafe. Our support team is here to help you."
            break;
        case 'hate/threatening':
            text = "We understand that it can be difficult to express yourself, especially when it comes to difficult topics. However, we have detected hate speech and threats of violence in your message, and we have frozen your conversation for review. Your safety, as well as the safety of others, is our top priority. We encourage you to reach out to our support team or to a crisis hotline if you need immediate assistance."
            break;
        case 'sexual':
            text = "We want to create a safe and comfortable space for you to express yourself. However, we have detected sexual content in your message that is not appropriate for our platform. We have frozen your conversation for review, and we encourage you to reach out to our support team if you have any questions or concerns."
            break;
        case 'sexual/minors':
            text = "We understand that talking about sensitive topics can be difficult. However, we have detected sexual content involving minors in your message and we have frozen your conversation for review. This is a serious violation of the law, and we will report it to the appropriate authorities if needed. If you need immediate assistance, please reach out to our support team or to a crisis hotline."
        break;
            case 'violence':
            text = "We understand that some experiences can be difficult to process and can lead to feelings of anger or frustration. However, we have detected content that promotes or glorifies violence in your message and we have frozen your conversation for review. Our support team is here to help you process your feelings in a safe and healthy way."
        break;
            case 'violence/graphic':
            text = "We understand that some experiences can be difficult to process and can lead to feelings of anger or frustration. However, we have detected content that depicts graphic violence in your message and we have frozen your conversation for review. We want to make sure that you have access to the appropriate resources and support. Our support team is here to help you process your feelings in a safe and healthy way."
        break;
        }
    return (
      <View style={{
        width: '100%',
        bottom: 0,
        left: 0,
      }}>
        {state.isFrozen === true && (
          <View style={{
            height: 150,
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Text
              style={{
                color: '#696969',
                textAlign: 'center',
                padding: 20,
              }}
            >{text}</Text>
          </View>
        )}
      </View>
    )
  }