import {
  View,
  Image,
} from 'react-native-web';

export default function Header() {
  return (
    <View style={{
      height: 70,
      borderColor: '#EBEBEB',
      borderBottomWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      shadowColor: '#EBEBEB',
      shadowOffset: {width: 0, height: 2},
      shadowOpacity: 1,
      shadowRadius: 14,
    }}>
      <Image
        source="aurora-logo.png"
        style={{
          height: 22,
          width: 110,
        }}
      />
    </View>
  )
}