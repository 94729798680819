import { useState } from "react";
import { Text, CheckBox, Pressable } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Header from '../components/Header'
import BackButton from '../components/BackButton';
import IntakeContainer from '../components/IntakeContainer';
import CheckboxContainer from '../components/CheckboxContainer';

import {
  getTherapyTraits
} from '../utils';

export default function TherapistPrefsScreen2({ dispatch} ) {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);

  return (
    <Container>
      <Header />
      <BackButton dispatch={dispatch} prevScreen='TherapyPrefs1' />
      <IntakeContainer>
        <Text
          style={{
            textAlign: 'center',
            padding: 30
          }}
        >I want Aurora to be...</Text>
        <CheckboxContainer>
          <CheckBox
            value={checked1}
            onValueChange={setChecked1}
            color='#999999'
          />
          <Text
            style={{
              paddingLeft: 15,
              // color: '#696969'
            }}
          >Insightful</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked2}
            onValueChange={setChecked2}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Observant</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked3}
            onValueChange={setChecked3}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Practical</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked4}
            onValueChange={setChecked4}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Empathetic</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked5}
            onValueChange={setChecked5}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Logical</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked6}
            onValueChange={setChecked6}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Patient</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked7}
            onValueChange={setChecked7}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Thoughtful</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked8}
            onValueChange={setChecked8}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >Direct</Text>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            value={checked9}
            onValueChange={setChecked9}
            color='#999999'
          />
          <Text
            style={{paddingLeft: 15,
              // color: '#696969'
            }}
          >I don't know</Text>
        </CheckboxContainer>
        <Pressable
          style={{
            width: 150,
            borderColor: '#EBEBEB',
            borderWidth: 1,
            borderRadius: 20,
            margin: 20,
            marginBottom: 50,
            shadowColor: '#EAEAEA',
            shadowOffset: {width: 0, height: 8},
            shadowOpacity: .5,
            shadowRadius: 14,
          }}
          onPress={() => {
            const therapistTraits = getTherapyTraits([checked1, checked2, checked3, checked4, checked5, checked6, checked7])
            dispatch({
              type: ACTIONS.SET_THERAPIST_TRAITS,
              payload: { therapistTraits: therapistTraits }
            })
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'About'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Next</Text>
        </Pressable>
        <Pressable
          style={{
            margin: 20,
            position: 'absolute',
            bottom: '2%',
            textDecorationLine: 'underline'
          }}
          onPress={() => {
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'About2'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Skip</Text>
        </Pressable>
      </IntakeContainer>
    </Container>
  )
}