import { View, Image, Text, Pressable } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

export default function WelcomeScreen({ dispatch }) {
  return (
    <View style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center' ,
      }}>
      <Image
        source="aurora-logo.png"
        style={{
          height: 35,
          width: 175
        }}
      />
      <Pressable
        style={{
          width: 150,
          borderColor: '#EBEBEB',
          borderWidth: 1,
          borderRadius: 20,
          margin: 10,
          shadowColor: '#EAEAEA',
          shadowOffset: {width: 0, height: 8},
          shadowOpacity: .5,
          shadowRadius: 14,
          margin: 30
        }}
        onPress={() => {
          dispatch({
            type: ACTIONS.CHANGE_SCREEN,
            payload: {screen: 'Name'}
          })
        }}
      >
        <Text style={{
          // fontSize: 16,
          color: '#696969',
          textAlign: 'center',
          padding: 10,
        }}>Begin</Text>
      </Pressable>
    </View>
  )
}