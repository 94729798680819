import {
  View,
  Text,
  Pressable
} from 'react-native-web';

import * as ACTIONS from '../constants/actions';

export default function NewSessionPopUp({ state = state, dispatch = () => {}, fetchGPTAnswer, createSessionNotesPrompt}) {
  const buttonColor = state.auroraIsThinking ? '#EBEBEB' : 'white';
  const buttonIsDisabled = state.auroraIsThinking
  return (
    <View style={{
      width: '100%',
      bottom: 0,
      left: 0,
    }}>
      {state.popUp && (
        <View style={{
          height: 140,
          paddingHorizontal: 20,
          paddingTop: 10,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Text
            style={{
              color: '#696969',
              textAlign: 'center',
              padding: 10,
              paddingHorizontal: 50
            }}
          >Do you want to continue your session or start a new one?</Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pressable
              style={{
                width: 110,
                borderColor: '#EBEBEB',
                borderWidth: 1,
                borderRadius: 20,
                margin: 10,
                shadowColor: '#EBEBEB',
                shadowOffset: {width: 2, height: 0},
                shadowOpacity: 1,
                shadowRadius: 14,
              }}
              onPress={(event) => {
                dispatch({
                  type: ACTIONS.TOGGLE_POPUP,
                  payload: !state.popUp
                })
              }}
            >
              <Text style={{
                // fontSize: 16,
                color: '#696969',
                textAlign: 'center',
                padding: 10,
              }}>Keep Chatting</Text>
            </Pressable>
            <Pressable
              disabled = {state.auroraIsThinking}
              style={{
                width: 110,
                borderColor: '#EBEBEB',
                borderWidth: 1,
                borderRadius: 20,
                margin: 10,
                shadowColor: '#EBEBEB',
                shadowOffset: {width: 2, height: 0},
                shadowOpacity: 1,
                shadowRadius: 14,
                backgroundColor: buttonColor
              }}
              onPress={(e) => {
                fetchGPTAnswer(createSessionNotesPrompt(state), state, dispatch)
                  .then((notes) => {
                    console.log('going to set the notes', notes);
                    dispatch({
                      type: ACTIONS.ADD_SESSION_NOTES,
                      payload: { notes: notes }
                    });
                    dispatch({
                      type: ACTIONS.SET_THINKING,
                      payload: false
                    });
                    dispatch({
                      type: ACTIONS.START_NEW_SESSION
                    })
                  })
              }}
            >
              <Text style={{
                // fontSize: 16,
                color: '#696969',
                textAlign: 'center',
                padding: 10
              }}>Start a New Session</Text>
            </Pressable>
          </View>
        </View>
      )}
    </View>
  )
}