import {
  View
} from 'react-native-web';

export default function CheckboxContainer({ children }) {
  return (
    <View style={{
      flexDirection: 'row',
      marginBottom: 20,
      // paddingLeft: 20,
      alignSelf: 'flex-start'
    }}>
      {children}
    </View>
  )
}