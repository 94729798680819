import {
  View
} from 'react-native-web';

export default function IntakeContainer({ children }) {
  return (
    <View style={{
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      // paddingBottom: '20%',
      alignSelf: 'center'
    }}>
      {children}
    </View>
  )
}