import './App.css';
import React, { useState, useEffect } from 'react';

import LoadingScreen from './screens/LoadingScreen';
import ChatScreen from './screens/ChatScreen';
import WelcomeScreen from './screens/WelcomeScreen';
import UserNameScreen from './screens/UserNameScreen';
import UserConcernsScreen from './screens/UserConcernsScreen';
import TherapistPrefsScreen1 from './screens/TherapistPrefsScreen1';
import TherapistPrefsScreen2 from './screens/TherapistPrefsScreen2';
import LanguageScreen from './screens/LanguageScreen';
import AboutScreen from './screens/AboutScreen';
import AboutScreen2 from './screens/AboutScreen2';

import Container from './components/Container';
import { useMagicReducer } from './hooks/useMagicReducer';

import {
  createTherapyStyleString,
  getTherapyTraits,
  getUserConcerns,
  isValidApiKey,
} from './utils';
import { LOCAL_STORAGE_ITEM_KEY, INITIAL_STATE } from './constants';
import reducer from './reducer';
import * as ACTIONS from './constants/actions';

function Router({initialState}) {
  const [state, dispatch] = useMagicReducer(reducer, initialState);

  if (!isValidApiKey(state.apiKey)) {
    const apiKey = process.env.REACT_APP_SECRET;
    if (isValidApiKey(apiKey)) {
      dispatch({
        type: ACTIONS.SET_API_KEY,
        payload: { apiKey: apiKey }
      })
      // console.log('key set', state.apiKey);
    }
  }

  return (
    <Container>
      {state.currentScreen === 'Welcome' && (
        <WelcomeScreen state={state} dispatch={dispatch} />
      )}
      {state.currentScreen === 'Name' && (
        <UserNameScreen state={state} dispatch={dispatch} />
      )}
      {state.currentScreen === 'Language' && (
        <LanguageScreen state={state} dispatch={dispatch} />
      )}
      {state.currentScreen === 'Concerns' && (
        <UserConcernsScreen state={state} dispatch={dispatch} getUserConcerns={getUserConcerns} />
      )}
      {state.currentScreen === 'TherapyPrefs1' && (
        <TherapistPrefsScreen1 state={state} dispatch={dispatch} createTherapyStyleString={createTherapyStyleString} />
      )}
      {state.currentScreen === 'TherapyPrefs2' && (
        <TherapistPrefsScreen2 state={state} dispatch={dispatch} getTherapyTraits={getTherapyTraits} />
      )}
      {state.currentScreen === 'About' && (<AboutScreen dispatch={dispatch} />)}
      {state.currentScreen === 'About2' && (<AboutScreen2 dispatch={dispatch} />)}
      {state.currentScreen === 'Chat' && (
        <ChatScreen state={state} dispatch={dispatch} />
      )}
    </Container>
  )
}


// APP LOADER

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialState, setInitialState] = useState();

  // window.localStorage.clear()

  useEffect(() => {
    const stateAsString = window.localStorage.getItem(LOCAL_STORAGE_ITEM_KEY);
    const storedState = JSON.parse(stateAsString);

    // if nothing stored in local storage, init state
    if (!stateAsString || !storedState?.sessions) {
      setInitialState(INITIAL_STATE)
    } else {
      // pull initial state baselines, replace items with stored state items where exist
      const storedStateNew = {
        ...INITIAL_STATE,
        ...storedState,
        // if current screen was chat, turn on new session pop up
        popUp: (storedState.currentScreen === 'Chat'),
        auroraIsThinking: false
      }
      setInitialState(storedStateNew)
    }

    setIsLoaded(true);
  }, [setInitialState, setIsLoaded]);

  return isLoaded
    ? <Router initialState={initialState} />
    : <LoadingScreen />;
}

export default App;