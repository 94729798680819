import { useEffect, useRef } from 'react';
import {  usePrevious } from 'react-use';
import {
  fetchGPTAnswer,
  fetchContentModeration,
  buildMessage,
  getLastMessage,
  getCurrentSession,
  createNextMessagePrompt,
  createChatPrompt,
  createSessionNotesPrompt,
  isValidApiKey,
} from '../utils';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Menu from '../components/Menu'
import Header from '../components/Header'
import Chat from '../components/Chat'
import NewSessionPopUp from '../components/NewSessionPopUp'
import Footer from '../components/Footer'
import FrozenSessionPopUp from '../components/FrozenSessionPopUp';

export default function ChatScreen({ state, dispatch }) {
  const prevMessages = usePrevious(getCurrentSession(state).messages) || [];
  const prevActiveMessage = usePrevious(state.currentMessage) || '';
  const scrollViewRef = useRef();

  // // auto scroll to bottom
  useEffect(() => {
    if (getCurrentSession(state).messages.length !== prevMessages.length || state.currentMessage !== prevActiveMessage || state.auroraIsThinking) {
      // Scroll messages to bottom
      scrollViewRef.current.scrollToEnd({ animated: false });
    }
  }, [state, dispatch, prevMessages, prevActiveMessage])


  // // first message
  useEffect(() => {
    const isTimetoTalk =  getCurrentSession(state).messages.length===0;

    // console.log(getCurrentSession(state).messages);
    // console.log(getCurrentSession(state).messages.length===0);
    console.log('time to talk', isTimetoTalk);
    console.log('aurora is thinking', state.auroraIsThinking);

    // known weird bug - on new session, fetchGPTAnswer always runs twice
    // needed to force a check to see if therapist already answered to not publish subsequent responses
    if (isTimetoTalk && !state.auroraIsThinking) {
      console.log('api is valid', isValidApiKey(process.env.REACT_APP_SECRET));
      // const prompt = createNextMessagePrompt(state);
      const prompt = createChatPrompt(state);
      const requestDetails = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            prompt,
            state
        })
      };
      dispatch({
          type: ACTIONS.SET_THINKING,
          payload: true
        })
        // console.log(requestDetails);
      // fetch('/.netlify/functions/gptResponse',requestDetails)
      fetchGPTAnswer(prompt, state, dispatch)
        .then((message) => {
          // console.log('response',response);
          // const returnedMessage = JSON.parse(response.body.message)
          // console.log(returnedMessage);
          if (getCurrentSession(state).messages.length===0) {
            // console.log(getCurrentSession(state).messages.length===0);
            const nextMessage = buildMessage(
              message,
              false
            );
            // set message
            console.log('going to set the next message');
            dispatch({
              type: ACTIONS.ADD_MESSAGE,
              payload: { message: nextMessage }
            });
            dispatch({
              type: ACTIONS.SET_THINKING,
              payload: false
            });
          }
        })
    }
  }, [state, dispatch]);

  // // therapist response
  useEffect(() => {

    const lastMessage = getLastMessage(getCurrentSession(state).messages);
    const isLastMessageFromUser = lastMessage?.isUser === true;
    console.log('last message from user',isLastMessageFromUser);

    if (isLastMessageFromUser && !state.auroraIsThinking && !state.isFrozen) {
      console.log('api is valid', isValidApiKey(process.env.REACT_APP_SECRET));
      fetchContentModeration(lastMessage.message, state, dispatch)
        .then ((data) => {
          let highestCategory = null
          // if message is flagged
          if (data.results[0].flagged) {
            // Get the category with the highest score
            highestCategory = Object.entries(data.results[0].category_scores).reduce((highest, [category, score]) => {
              if (!highest || score > highest[1]) {
                  return [category, score];
              }
              return highest;
            }, null)[0];
            console.log(highestCategory);
            dispatch({
              type: ACTIONS.SET_FROZEN,
              payload: {
                isFrozen: true, 
                freezeReason: highestCategory
              }
            })
          } else {
            const prompt = createChatPrompt(state)
            dispatch({
                type: ACTIONS.SET_THINKING,
                payload: true
              })
            // get next response
            fetchGPTAnswer(prompt, state, dispatch)
              .then((response) => {
                if (isLastMessageFromUser || getCurrentSession(state).messages.length===0) {
                  console.log(isLastMessageFromUser || getCurrentSession(state).messages.length===0);
                  const responseMessages = response.split('\n\n')
                  for (let i = 0; i < responseMessages.length; i++) {
                    let msg = responseMessages[i];
                    const nextMessage = buildMessage(
                      msg,
                      false
                    );
                    // if its a second message, wait 2 seconds
                    if (i>0) {
                      setTimeout(() => {
                        // set message
                        console.log('going to set the next message');
                        dispatch({
                          type: ACTIONS.ADD_MESSAGE,
                          payload: { message: nextMessage }
                        });
                      }, 1000);
                      // if it's the first time, just set the message
                    } else {
                      // set message
                      console.log('going to set the next message');
                      dispatch({
                        type: ACTIONS.ADD_MESSAGE,
                        payload: { message: nextMessage }
                      });
                    }
                  }
                  // stop thinking
                  dispatch({
                    type: ACTIONS.SET_THINKING,
                    payload: false
                  })
              }
            })
          }
        })
        }
  }, [state, dispatch]);

  return (
    <Container>
        <Menu state={state} dispatch={dispatch} />
        <Header />
        <Chat sessions={state.sessions} isTyping={state.auroraIsThinking} scrollViewRef={scrollViewRef} />
        <NewSessionPopUp state={state} dispatch={dispatch} fetchGPTAnswer={fetchGPTAnswer} createSessionNotesPrompt={createSessionNotesPrompt}/>
        <FrozenSessionPopUp state={state} />
        <Footer state={state} dispatch={dispatch}/>
   </Container>
  );
}