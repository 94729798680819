import {
  View,
  Text
} from 'react-native-web';

export default function Message({ message }) {
  const messageText = message.message
  const isUser = message.isUser
  // prefer #ECEDF2 but typing gif is #ECECEC
  const backgroundColor = isUser ? '#D7EDFF' : '#ECEDF2';
  const maxWidth = '75%';
  const alignSelf = isUser ? 'flex-end' : 'flex-start'
  return (
    // <View>
      <View style={{
        backgroundColor,
        paddingTop: 4,
        paddingBottom: 5,
        paddingHorizontal: 12,
        marginVertical: 6,
        borderRadius: 15,
        maxWidth,
        alignSelf,
        flexDirection: 'row'
      }}>
        <Text style={{
          fontSize:16
        }}>{messageText}</Text>
      </View>
    //   <FeedbackIcons isUser = {isUser} />
    // </View>
  )
}