import {
  buildSession,
  getLastMessage,
  getPreviousSession,
  getCurrentSession
} from './utils';

import * as ACTIONS from './constants/actions';
import {INITIAL_STATE} from './constants';

export default (state, action) => {
  // establish current session
  const currentSession = getCurrentSession(state)
  const previousSession = getPreviousSession(state)
  const lastMessage = getLastMessage(currentSession)

  switch (action.type) {
    case ACTIONS.ADD_MESSAGE:
      // change current session's messages to add new message
      currentSession.messages = [...currentSession.messages, action.payload.message]
      // return state where sessions is now all previous sessions and the altered current session
      return {
            ...state,
            sessions: [
              ...state.sessions.slice(0,state.sessions.length-1),
              currentSession
            ]
          };
    case ACTIONS.CHANGE_SCREEN:
      return {
        ...state,
        currentScreen: action.payload.screen
      };
    case ACTIONS.SET_USER_NAME:
      return {
        ...state,
        userName: action.payload.name
      }
    case ACTIONS.SET_USER_THERAPYBEFORE:
      return {
        ...state,
        userTherapyBefore: action.payload
      }
    case ACTIONS.SET_THERAPY_STYLE:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          therapyStyle: action.payload.therapyStyle
        }
      }
    case ACTIONS.SET_USER_LANGUAGE:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          language: action.payload.language
        }
      }
    case ACTIONS.SET_THERAPIST_TRAITS:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          therapistTraits: action.payload.therapistTraits
        }
      }
    case ACTIONS.SET_USER_MH_CONCERNS:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          userConcerns: action.payload.userConcerns
        }
      }
    case ACTIONS.SET_API_KEY:
      return {
        ...state,
        apiKey: action.payload.apiKey
      };
    case ACTIONS.TOGGLE_MENU:
      return {
        ...state,
        menuClosed: action.payload
      };
    case ACTIONS.TOGGLE_POPUP:
      return {
        ...state,
        popUp: action.payload
      };
    case ACTIONS.START_NEW_SESSION:
      // change current session's status to inactive
      currentSession.active = false
      // return new state where sessions is all previous sessions, the altered current session and a new session
      return {
        ...state,
        sessions: [
          ...state.sessions.slice(0,state.sessions.length-1),
          currentSession,
          buildSession()
        ],
        popUp: false
      };
    case ACTIONS.ADD_SESSION_NOTES:
      // change previous session's notes to passed notes
      currentSession.notes = action.payload.notes
      // return new state where sessions is all historical sessions, updated previous session and current session
      return {
        ...state,
        sessions: [
          ...state.sessions.slice(0,state.sessions.length-1),
          currentSession
        ]
      };
    case ACTIONS.SET_THINKING:
      return {
        ...state,
        auroraIsThinking: action.payload
      }
    case ACTIONS.SET_FROZEN:
      return {
        ...state,
        isFrozen: action.payload.isFrozen,
        freezeReason: action.payload.freezeReason
      }
    case ACTIONS.SET_CURRENT_MESSAGE:
      return {
        ...state,
        currentMessage: action.payload.activeMessage
      }
    case ACTIONS.RESET_APP_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}