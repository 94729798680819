import { useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  Pressable,
} from 'react-native-web';
import { buildMessage } from '../utils';

import * as ACTIONS from '../constants/actions';

export default function Footer({ state, dispatch = () => {} }) {
  const textInputRef = useRef();
  const backgroundColor = 'white';
  // let activeMessage = '';
  const _handleChange = (e) => {
    e.target.style.height = 0
    e.target.style.height = `${e.target.scrollHeight+2}px`
    };

  return (
    <View style={{
      // height: 30,
      backgroundColor,
      justifyContent: 'center',
      paddingHorizontal: 20,
      flexDirection: 'row',
      marginVertical: 15,

    }}>
      {/* https://necolas.github.io/react-native-web/docs/text-input/ */}
      <TextInput
        ref={textInputRef}
        editable={!state.isFrozen}
        multiline={true}
        onChange={_handleChange}
        // numberofLines = {4}
        placeholder="Type your message"
        placeholderTextColor="#C5C5C6"
        autoComplete="autocomplete"
        autoCorrect="on"
        autoCapitalize="sentences"
        onChangeText={(text) => {
          // activeMessage=text
          // set stateful active message variable with dispatch
          dispatch({
            type: ACTIONS.SET_CURRENT_MESSAGE,
            payload: { activeMessage: text }
          })
        }}
        onSubmitEditing={(e) => {
          const value = e.target.value;
          const nextMessage = buildMessage(value, true)

          dispatch({
            type: ACTIONS.ADD_MESSAGE,
            payload: { message: nextMessage }
          })

          textInputRef.current.value = '';
        }}
        style={{
          flex: 1,
          // input
          borderColor: '#BFBFBF',
          borderWidth: 1,
          paddingTop: 4,
          paddingBottom: 5,
          paddingHorizontal: 12,
          borderRadius: 15,
          marginRight: 5,

          // text
          fontSize: 16,
        }}
      />
      <Pressable
        onPress={() => {
          console.log(state.currentMessage);
          const value = state.currentMessage;
          const nextMessage = buildMessage(value, true)

          dispatch({
              type: ACTIONS.ADD_MESSAGE,
              payload: { message: nextMessage }
          })

          textInputRef.current.value = '';
          textInputRef.current.style.height = '30px';
        }}
        style={{
          justifyContent: 'Center',
          marginTop: 'auto',
          backgroundColor: '#D7EDFF',
          height: 30,
          paddingTop: 4,
          paddingBottom: 5,
          paddingHorizontal: 7.5,
          borderRadius: 15,
        }}
      >
        <Text style={{
          fontWeight: 'bold',
          fontSize: 20,
          color: 'gray'
        }}>
          &#x2191;
        </Text>
      </Pressable>
    </View>
  );
}