import { useState } from "react";
import { Text, Pressable, TextInput, CheckBox, View } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Header from '../components/Header'
import IntakeContainer from '../components/IntakeContainer';

export default function UserNameScreen({ dispatch }) {
  let userName = '';
  const [checked1, setChecked1] = useState(false);
  return (
    <Container>
      <Header />
      <IntakeContainer>
        <Text
          style={{
            textAlign: 'center',
            padding: 30
          }}
        >What is your name?</Text>
        <TextInput
            placeholder="First name"
            placeholderTextColor="#C5C5C6"
            autoComplete="autocomplete"
            // autoCorrect="on"
            autoCapitalize="sentences"
            onChangeText={(text) => userName=text}
            onSubmitEditing={(e) => {
              const value = e.target.value;

              dispatch({
                  type: ACTIONS.SET_USER_NAME,
                  payload: { name: value }
                })
              dispatch({
                type: ACTIONS.CHANGE_SCREEN,
                payload: {screen: 'Language'}
              })

            }}
            style={{
              // flex: 1,
              // input
              borderColor: '#BFBFBF',
              borderWidth: 1,
              paddingTop: 4,
              paddingBottom: 5,
              paddingHorizontal: 12,
              borderRadius: 15,

              // text
              fontSize: 16,
            }}
          />
        <View style={{
          flexDirection: 'row',
          margin: 20,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CheckBox
            value={checked1}
            onValueChange={setChecked1}
            color='#999999'
          />
          <Text
            style={{
              paddingLeft: 15,
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              // color: '#696969'
            }}
          >Prefer not to say</Text>
        </View>
        <Pressable
          style={{
            width: 150,
            borderColor: '#EBEBEB',
            borderWidth: 1,
            borderRadius: 20,
            margin: 20,
            shadowColor: '#EAEAEA',
            shadowOffset: {width: 0, height: 8},
            shadowOpacity: .5,
            shadowRadius: 14,
          }}
          onPress={() => {
            dispatch({
              type: ACTIONS.SET_USER_NAME,
              payload: { name: userName }
            })
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'Language'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Next</Text>
        </Pressable>
        <Pressable
          style={{
            margin: 20,
            position: 'absolute',
            bottom: '10%',
            textDecorationLine: 'underline'
          }}
          onPress={() => {
            dispatch({
              type: ACTIONS.CHANGE_SCREEN,
              payload: {screen: 'About2'}
            })
          }}
        >
          <Text style={{
            // fontSize: 16,
            color: '#696969',
            textAlign: 'center',
            padding: 10,
          }}>Skip</Text>
        </Pressable>
        </IntakeContainer>
    </Container>
  )
}