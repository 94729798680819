import { createReducer } from 'react-use';
import logger from 'redux-logger'

import { LOCAL_STORAGE_ITEM_KEY } from '../constants';

const localStorageMiddleware = ({ getState }) => next => action => {
  next(action);

  const stateAsString = JSON.stringify(getState())
  window.localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, stateAsString);
}

export const useMagicReducer = createReducer(
  logger,
  localStorageMiddleware
);