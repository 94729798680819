import {
  ScrollView,
  Image
} from 'react-native-web';

import Session from './Session';

export default function Chat({ sessions = [], isTyping, scrollViewRef }) {
  return (
    <ScrollView
      ref={scrollViewRef}
      style={{
        paddingHorizontal: 20,
        paddingVertical: 10
      }}
      contentContainerStyle={{ flex: 1 }}
    >
      {sessions.map((it, index) => (
        <Session
          key={index.toString()}
          session={it}
        />
      ))}
      {isTyping && <Image
        source="typing-animation-3x.gif"
        style={{
          height: 30,
          width: 48,
          marginTop: 5
        }}
      />}
    </ScrollView>
  );
}