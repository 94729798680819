import {
  View,
  Text
} from 'react-native-web';

import Message from './Message';

export default function Session({ session }) {
  const messages = session.messages
  const startTime = session.startTime
  return (
    <View>
      <View
        style={{
          alignItems: 'Center',
          padding: 5
        }}
      >
        <Text
          style={{
            color: '#565656',
            fontSize: 12
          }}
        >
          New Session
        </Text>
        <Text
          style={{
            color: '#565656',
            fontSize: 12
          }}
        >
          {startTime}
        </Text>
      </View>
      {messages.map((it, index) => (
        <Message
          key={index.toString()}
          message={it}
        />
      ))}
    </View>
  );
}