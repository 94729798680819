import { buildSession } from '../utils';

const initialSession = [buildSession()]

export const LOCAL_STORAGE_ITEM_KEY = '@AURORA_APP_STATE_2';
export const INITIAL_STATE = {
  apiKey: null,
  sessions: initialSession,
  userName: null,
  userGender: null,
  userAge: null,
  userRace: null,
  userSexuality: null,
  userTherapyBefore: null,
  userPreferences: null,
  menuClosed: true,
  popUp: false,
  currentScreen: 'Welcome',
  auroraIsThinking: false,
  isFrozen: false,
  freezeReason: null,
  currentMessage: null,
}