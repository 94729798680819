import {
  View,
  Text,
  Pressable,
  TouchableOpacity,
} from 'react-native-web';
import * as ACTIONS from '../constants/actions';

export default function Menu({ state = state, dispatch = () => {} }) {
  return (
    <View style={{
      // flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    }}>
      {!state.menuClosed && (
        <View style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 320,
          flex: 1,
          paddingHorizontal: 40,
          paddingVertical: 75,
          backgroundColor: 'white',
          overflow: 'hidden',
          shadowColor: '#EBEBEB',
          shadowOffset: {width: 2, height: 0},
          shadowOpacity: 1,
          shadowRadius: 14,
        }}>
          {/* <MenuItem title='Reset' dispatch={dispatch} /> */}
          <TouchableOpacity
            style={{
              padding: 16,
              paddingVertical: 20,
              flexDirection: 'row',
              alignItems: 'center',
              borderColor: '#BFBFBF',
              borderBottomWidth: 1,
            }}
            onPress={() => {
              dispatch({
                type: ACTIONS.RESET_APP_STATE
              })
            }}>
            <Text style={{
              marginLeft: 16,
              fontWeight: 'bold',
              color: 'gray',
            }}>Clear History</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              padding: 16,
              paddingVertical: 20,
              flexDirection: 'row',
              alignItems: 'center',
              borderColor: '#BFBFBF',
              borderBottomWidth: 1,
            }}
            onPress={() => {
              dispatch({
                type: ACTIONS.CHANGE_SCREEN,
                payload: {screen: 'Name'}
              })
              dispatch({
                type: ACTIONS.TOGGLE_MENU,
                payload: true
              })
            }}>
            <Text style={{
              marginLeft: 16,
              fontWeight: 'bold',
              color: 'gray',
            }}>Set Preferences</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              padding: 16,
              paddingVertical: 20,
              flexDirection: 'row',
              alignItems: 'center',
              borderColor: '#BFBFBF',
              borderBottomWidth: 1,
            }} >
            <Text style={{
              marginLeft: 16,
              fontWeight: 'bold',
              color: 'gray',
            }}
            onPress={() => {
              dispatch({
                type: ACTIONS.CHANGE_SCREEN,
                payload: {screen: 'About'}
              })
              dispatch({
                type: ACTIONS.TOGGLE_MENU,
                payload: true
              })
            }}>How Aurora Works</Text>
          </TouchableOpacity>
        </View>
      )}
      <Pressable
        style={{
          position: 'absolute',
          top: 12,
          left: 25,
        }}
        onPress={(event) => {
          dispatch({
            type: ACTIONS.TOGGLE_MENU,
            payload: !state.menuClosed
          })
        }}
      >
        <Text style={{
          fontSize: 33,
          color: '#BFBFBF',
        }}>&#9776;</Text>
      </Pressable>
    </View>
  )
}