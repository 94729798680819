export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGE_CM = 'ADD_MESSAGE_CM';
export const CHANGE_SCREEN = 'CHANGE_SCREEN';
export const SET_API_KEY = 'SET_API_KEY';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_GENDER = 'SET_USER_GENDER';
export const SET_USER_AGE = 'SET_USER_AGE';
export const SET_USER_RACE = 'SET_USER_RACE';
export const SET_USER_SEXUALITY = 'SET_USER_SEXUALITY';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_THERAPYBEFORE = 'SET_USER_THERAPYBEFORE';
export const SET_USER_MH_CONCERNS = 'SET_USER_MH_CONCERNS';
export const SET_THERAPY_STYLE = 'SET_THERAPY_STYLE';
export const SET_THERAPIST_TRAITS = 'SET_THERAPIST_TRAITS';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const START_NEW_SESSION = 'START_NEW_SESSION';
export const ADD_SESSION_NOTES = 'ADD_SESSION_NOTES';
export const SET_THINKING = 'SET_THINKING';
export const RESET_APP_STATE = 'RESET_APP_STATE';
export const SET_FROZEN = 'SET_FROZEN';
export const SET_CURRENT_MESSAGE = 'SET_CURRENT_MESSAGE';