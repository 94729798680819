import { Text, Pressable } from 'react-native-web';

import * as ACTIONS from '../constants/actions';

import Container from '../components/Container';
import Header from '../components/Header';
import BackButton from '../components/BackButton';
import IntakeContainer from '../components/IntakeContainer';

export default function AboutScreen({ dispatch }) {
    const description = "Aurora is an AI mental health advocate to help you talk through your feelings and thoughts. She has been customized based on the answers to your questions, adopting a personality and communication style that is tailored to your individual preferences and desired topic(s). She is available 24/7, without the need for appointments or waitlists."
  return (
    <Container>
      <Header />
      <BackButton dispatch={dispatch} prevScreen='TherapyPrefs2' />
      <IntakeContainer>
        <Text
          style={{
            textAlign: 'center',
            paddingHorizontal: 50,
            paddingVertical: 30,
            fontSize: 18,
            maxWidth: 400,
            color: '#696969'
          }}
        >{description}</Text>
        <Pressable
        style={{
          width: 150,
          borderColor: '#EBEBEB',
          borderWidth: 1,
          borderRadius: 20,
          margin: 10,
          shadowColor: '#EAEAEA',
          shadowOffset: {width: 0, height: 8},
          shadowOpacity: .5,
          shadowRadius: 14,
        }}
        onPress={() => {
          dispatch({
            type: ACTIONS.CHANGE_SCREEN,
            payload: {screen: 'About2'}
          })
        }}
      >
        <Text style={{
          // fontSize: 16,
          color: '#696969',
          textAlign: 'center',
          padding: 10,
        }}>Next</Text>
      </Pressable>
      </IntakeContainer>
    </Container>
  )
}